export const NODE_ENV = process.env.NODE_ENV;
export const isDevelopment = NODE_ENV !== 'production';
export const CUSTOMER_API_BASE = 'https://dev.api.readyspaces.com/customers/crm/v1';
export const PG_API_BASE = 'https://dev.api.readyspaces.com/pg/api/v1';
export const EMAIL_API_BASE = 'https://dev.api.readyspaces.com/emails/v1';
export const ADMIN_API_BASE = 'https://dev.api.readyspaces.com/admin/api/v1';
export const AUTH_API_BASE = 'https://dev.api.readyspaces.com/auth/private';
export const UNITS_API_BASE = 'https://dev.api.readyspaces.com/admin/api/units/v1';
export const UPLOAD_API = 'https://dev.api.readyspaces.com/uploader/private/upload';
export const RS_ANVIL_API = 'https://dev.api.readyspaces.com/anvil/api';
export const ANVIL_TEMPLATE_ID = 'aCKbJNpwh4CiZrtB9X8P';
export const SSO_API_BASE = 'https://dev.sso.api.readyspaces.com/auth/';
